import keymaster from 'keymaster';
import PageComponent from '../component/page-component';
import fieldsGroupMixin from './fields-group-mixin';
import discoverFieldsMixin from './discover-fields-mixin';
import formConstants from './_constants';


class Form extends discoverFieldsMixin(fieldsGroupMixin(PageComponent)) {

	constructor({
		root,
		element,
		async = false,
		autoAddFields = true
	}) {
		super({root: root, element: element, async: async});
		this.autoAddFields = autoAddFields;
		this.submitEnabled = true;
	}



	prepare() {
		this.listeners.submit = this.events.on(this.element, 'submit', this.onSubmit.bind(this));
		if (this.autoAddFields) {
			this.discoverFields(this.element);
		}
		this.initConditionalFields();
		this.initKeyShortcuts();
	}


	clear() {
		this.clearKeyShortcuts();
	}


	initKeyShortcuts() {
		const activeElements = this.element.querySelectorAll(this.dataSelector('keyShortcuts'));
		this.shortcutsMap = new Set();
		this.previousKeymasterFilter = keymaster.filter;
		keymaster.filter = (event) => {
			if (this.element.contains(event.target)) {
				return true;
			}
			return this.previousKeymasterFilter(event);
		};

		for (const activeElement of activeElements) {
			const shortcut = this.dataAttr(activeElement).get('keyShortcuts', '');
			if (shortcut.length && !this.shortcutsMap.has(shortcut)) {
				this.shortcutsMap.add(shortcut);
				keymaster(shortcut, (event) => { event.preventDefault(); activeElement.click(); });
			}
		}
	}


	clearKeyShortcuts() {
		if (this.shortcutsMap) {
			for (const shortcut of this.shortcutsMap.values()) {
				keymaster.unbind(shortcut);
			}
			keymaster.filter = this.previousKeymasterFilter;
			this.previousKeymasterFilter = null;
			this.shortcutsMap.clear();
			this.shortcutsMap = null;
		}
	}


	initConditionalFields() {
		this.conditionalFields = new Map();
		const fields = this.element.querySelectorAll(this.dataSelector(formConstants.fieldSelectorAttribute) + this.dataSelector(formConstants.conditionalAttribute));
		for (const fieldElement of fields) {
			const field = this.getComponent(fieldElement);
			const conditions = this.dataAttr(fieldElement).get(formConstants.conditionalAttribute);
			for (const condition of conditions) {
				for (const trigger of condition.when) {
					const names = trigger.name.split('.');
					// eslint-disable-next-line smells/no-this-assign
					let target = this;
					for (const name of names) {
						target = target.getField(name);
					}
					trigger.target = target;
					if (!('operator' in trigger)) {
						trigger.operator = '=';
					}
				}
			}
			this.conditionalFields.set(field, conditions);
		}
		if (this.conditionalFields.size) {
			this.listeners.fieldChange = this.events.on(this.element, this.dataSelector(formConstants.fieldSelectorAttribute), 'field:change', this.onFieldChange.bind(this), {capture: true});
			this.updateConditionalFields();
		}
	}


	onFieldChange(event) {
		this.updateConditionalFields();
	}


	updateConditionalFields() {
		for (const [field, conditions] of this.conditionalFields) {
			for (const condition of conditions) {
				let match = true;
				for (const trigger of condition.when) {
					match = match && this.checkCondition(trigger.target, trigger.operator, trigger.value);
					if (!match) {
						break;
					}
				}
				this.applyConditionEffects(field, condition.then, match);
			}
		}
	}


	checkCondition(target, operator, value) {
		const checkValue = target.getValue();
		switch (operator) {
			case '!=':
				return String(checkValue) !== String(value);
			case '=':
			// no-break
			// eslint-disable-next-line no-fallthrough
			default:
				return String(checkValue) === String(value);
		}
	}


	applyConditionEffects(field, effects, match) {
		for (const name in effects) {
			if (effects.hasOwnProperty(name)) {
				const value = effects[name];
				// eslint-disable-next-line default-case
				switch (name) {
					case 'visible':
						field.toggleVisibility(match ? value : !value);
						break;
					case 'hidden':
						field.toggleVisibility(match ? !value : value);
						break;
					case 'enabled':
						field.toggleEnabled(match ? value : !value);
						break;
					case 'disabled':
						field.toggleEnabled(match ? !value : value);
						break;
					case 'value':
						if (match && field.getValue() !== value) {
							field.setValue(value);
							this.updateConditionalFields();
						}
				}
			}
		}
	}


	focus() {
		if (this.fields) {
			for (const field of this.fields.values()) {
				if (field.canFocus()) {
					field.focus();
					break;
				}
			}
		}
		return this;
	}


	disableSubmit() {
		this.submitEnabled = false;
		return this;
	}


	enableSubmit() {
		this.submitEnabled = true;
		return this;
	}


	onSubmit(event) {
		event.preventDefault();
		if (
			document.activeElement &&
			document.activeElement.tagName.toLowerCase() === 'button' &&
			document.activeElement.hasAttribute('type') &&
			document.activeElement.getAttribute('type') === 'submit'
		) {
			document.activeElement.blur();
		}
		if (this.submitEnabled) {
			this.events.trigger(this.element, 'form:submit', {wrapper: this});
		}
	}

}


export default Form;
